<script setup lang="ts">
import type { Organization } from "@/model/app/organization"
import type { Championship } from "@/model/championship/championship"
import type { ChampionshipEventType } from "@/model/championship/championship-event-type"
import type { Issue } from "@/model/tasks/issue"
import {
    useOrganizationSelection,
    type SelectionTableOrganization,
} from "@/pages/App/Organization/useOrganizationSelection"
import { $currency, $date, useAppConfig, useHttpClient } from "@/vf"
import { ref } from "vue"
import { useRouter } from "vue-router"

const props = defineProps<{
    event: Championship
}>()

const http = useHttpClient()
const router = useRouter()
const { appConfig } = useAppConfig()

const organizationService = useOrganizationSelection()
if (appConfig.value.account) {
    await organizationService.fetchOrganizations()
}
const selection = ref(organizationService.tree)

async function select(org: SelectionTableOrganization | Organization) {
    const issue = (await http.post<Issue>(`/championship/registration/${props.event.id}/create-issue/${org.id}`)).data
    await router.push({ name: "@Tasks.Customer.index", query: { issue: issue.id } })
}
</script>
<template>
    <div class="event-card-show overflow-hidden mb-4">
        <div class="event-card-show__inner">
            <div class="row">
                <div class="col-xl-3 col-lg-6 col-12 order-1">
                    <h3 class="event-card-show__title">{{ $t("@championship:championship.eventInformation") }}</h3>
                    <p class="mb-0">
                        <b>{{ $t("@championship:championship.eventType") }}:</b>
                        {{
                            (props.event.eventTypes as ChampionshipEventType[] | undefined)?.map(c => c.name).join(", ")
                        }}
                    </p>
                    <p>
                        <b>{{ $t("@championship:championship.bids") }}:</b>
                        {{ props.event.bids?.map(c => c.name).join(", ") }}
                    </p>
                    <address>
                        {{ props.event.brandName }}
                        <br />
                        {{ props.event.brandStreet }} {{ props.event.brandStreetNumber }}
                        <br />
                        {{ props.event.brandZip }} {{ props.event.brandCity }}
                    </address>
                    <b>{{ $t("@championship:championship.location") }}:</b>
                    <address>
                        {{ props.event.location }}
                        <br />
                        {{ props.event.locationStreet }} {{ props.event.locationStreetNumber }}
                        <br />
                        {{ props.event.locationZip }} {{ props.event.locationCity }}
                    </address>
                </div>
                <div
                    class="col-xl-5 col-12 order-3 order-xl-2 col-12 d-none d-lg-block"
                    v-for="price of props.event.prices"
                >
                    <h3 class="event-card-show__title">{{ $t("@championship:championship.priceInformation") }}</h3>
                    <table class="table event-card-show__table">
                        <tbody>
                            <tr>
                                <th>{{ price.name }}</th>
                                <th class="text-right">{{ $t("@championship:championship.prices.early_rate") }}</th>
                                <th class="text-right">
                                    {{ $t("@championship:championship.prices.normal_rate") }}
                                </th>
                            </tr>
                            <tr>
                                <td>{{ $t("@championship:championship.prices.active_participant") }}</td>
                                <td class="text-right">{{ $currency(price.priceEarlyActiveCents) }}</td>
                                <td class="text-right">{{ $currency(price.priceNormalActiveCents) }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t("@championship:championship.prices.plus_category") }}</td>
                                <td class="text-right">{{ $currency(price.priceEarlyAdditionalCategoryCents) }}</td>
                                <td class="text-right">
                                    {{ $currency(price.priceNormalAdditionalCategoryCents) }}
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t("@championship:championship.prices.coach") }}</td>
                                <td class="text-right">{{ $currency(price.priceEarlyCoachCents) }}</td>
                                <td class="text-right">{{ $currency(price.priceNormalCoachCents) }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t("@championship:championship.prices.escort") }}</td>
                                <td class="text-right">{{ $currency(price.priceEarlyEscortCents) }}</td>
                                <td class="text-right">{{ $currency(price.priceNormalEscortCents) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-xl-4 col-lg-6 col-12 order-2 order-xl-2">
                    <div class="event-card-show__registration d-none d-lg-block">
                        <h3 class="event-card-show__title">{{ $t("@championship:championship.registration") }}</h3>
                        <p>
                            {{ $t("@championship:championship.registration." + props.event.registrationStatus) }}
                            <time
                                :datetime="props.event.registrationFrom"
                                v-if="props.event.registrationStatus == 'willOpen'"
                            >
                                {{ $date(props.event.registrationFrom) }}
                            </time>
                        </p>

                        <template v-if="props.event.registrationStatus == 'open' && selection.length > 0">
                            <h4 class="event-card-show__title mt-4">
                                {{ $t("@championship:championship.organization") }}
                            </h4>
                            <template v-for="org in selection">
                                <div v-if="org.organizationLevel === 'main'">
                                    <div class="row">
                                        <div class="col-md-4">
                                            {{ org.name }}
                                        </div>
                                        <div class="col-md-2">
                                            <button class="btn btn-primary" @click="select(org)">
                                                {{ $t("@championship:registration.window.organization.button") }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row my-2" v-for="child in org.children">
                                        <div class="col-md-4 pl-5">
                                            {{ child.name }}
                                        </div>
                                        <div class="col-md-2">
                                            <button class="btn btn-primary" @click="select(child)">
                                                {{ $t("@championship:registration.window.organization.button") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                    <RouterLink
                        :to="props.event.landingPage.path"
                        class="btn btn-primary mt-4"
                        v-if="props.event.landingPage"
                    >
                        {{ $t("@championship:championship.landingPage.link") }}
                    </RouterLink>
                </div>
            </div>
            <div class="row"></div>
        </div>
    </div>
</template>
