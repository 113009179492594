import { useGlobalState } from "@/stores/useGlobalState"
import { useAppConfig, type AppConfigMenuNode } from "@/vf"
import { type MaybeRef } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { computed, ref, unref, watch, type Ref } from "vue"
import { useRouter } from "vue-router"

export interface VfMenuItem extends AppConfigMenuNode {
    // patched in menu builder
    hasActiveChildren: boolean
    children?: VfMenuItem[]
}

interface VfRoutableMenuItem extends VfMenuItem {
    url: string
}

export function useMenuBuilder() {
    const router = useRouter()
    const { appConfig } = useAppConfig()

    const activeMenuItem: Ref<VfMenuItem> = ref(null)
    const menuItems: Ref<VfMenuItem[]> = ref([])
    const routableMenuItems = ref<VfRoutableMenuItem[]>([])
    const { globalState } = storeToRefs(useGlobalState())

    function onMenuChange(cb: () => any) {
        watch(appConfig, cb)
        watch(router.currentRoute, cb)

        cb()
    }

    onMenuChange(update)

    function update() {
        if (!appConfig.value.menu) {
            return
        }

        menuItems.value = _convertMenuItems(appConfig.value.menu)

        // create a flat list of all menu items that can be navigated to and resolve the url
        routableMenuItems.value = _prepareRoutableMenuItems(menuItems.value)

        // find the best matched menu item by url (see _getActiveItemBestMatchByUrl)
        activeMenuItem.value = _getActiveItemBestMatchByUrl(router.currentRoute.value.fullPath)

        // flag the menu hierarchy that leads to the best match with the `hasActiveChildren` flag
        _fillHasActiveChildren(menuItems.value)
    }

    function _convertMenuItems(menu: AppConfigMenuNode[]): VfMenuItem[] {
        return menu.map(node => {
            const item: VfRoutableMenuItem = {
                id: node.id,
                label: node.label,
                params: node.params,
                settings: node.settings,
                route: node.route,

                // url is required to find the best matched menu item (see _getActiveItemBestMatchByUrl)
                url: null,
                hasActiveChildren: false,
            }

            if (node.children && node.children.length > 0) {
                item.children = _convertMenuItems(node.children)
            }

            return item
        })
    }

    /**
     * Creates a flat list of all routable menu items
     */
    function _prepareRoutableMenuItems(menu: VfMenuItem[]): VfRoutableMenuItem[] {
        let elements: VfRoutableMenuItem[] = []

        for (const item of menu) {
            const routableItem = { ...item } as VfRoutableMenuItem
            delete routableItem.children

            if (item.children && item.children.length > 0) {
                elements = elements.concat(_prepareRoutableMenuItems(item.children))
            }

            if (item.route) {
                routableItem.url = router.resolve({ name: item.route, params: item.params }).href

                // required if navigation is not done via pushState()
                if (routableItem.url.startsWith("#")) {
                    routableItem.url = routableItem.url.substring(1)
                }

                elements.push(routableItem)
            }
        }

        return elements
    }

    /*─────────────────────────────────────┐
    │        find active menu item         │
    └─────────────────────────────────────*/
    function _getActiveItemBestMatchByUrl(currentUrl: string): VfMenuItem {
        const bestMatch = { length: 0, item: null }

        for (const menuItem of routableMenuItems.value) {
            if (!currentUrl.startsWith(menuItem.url)) {
                continue
            }

            if (currentUrl === menuItem.url) {
                return menuItem
            }

            if (menuItem.url.length > bestMatch.length) {
                bestMatch.length = menuItem.url.length
                bestMatch.item = menuItem
            }
        }

        return bestMatch.item
    }

    /**
     * Updates the `hasActiveChildren` flag of all menu items and sub menu items
     */
    function _fillHasActiveChildren(menu: VfMenuItem[]) {
        for (const child of menu) {
            child.hasActiveChildren = elementsContainElement(child, activeMenuItem.value)

            if (child.children) {
                _fillHasActiveChildren(child.children)
            }
        }
    }

    function getFirstChildRoutingData(itemRef: MaybeRef<VfMenuItem>): { name: string; params: any } {
        const item = unref(itemRef)

        if (!item) {
            return null
        }

        if (item.route) {
            return { name: item.route, params: item.params }
        }

        for (const child of item.children) {
            if (child.route) {
                // const preparedParams = {}

                // for (const [key, value] of Object.entries(child.params)) {
                //     let newValue: string | string[] = value

                //     if (value?.includes("/")) {
                //         newValue = value.split("/")

                //         console.log("replace path params", value, newValue)
                //     }

                //     preparedParams[key] = newValue
                // }

                return { name: child.route, params: child.params }
            }

            if (child.children && child.children.length > 0) {
                return getFirstChildRoutingData(child)
            }
        }
    }

    function hasRootMenu(menuId: string): Ref<boolean> {
        return computed(() => getRootMenu(menuId) !== undefined)
    }

    function getRootMenu(menuId: string): Ref<VfMenuItem> {
        return computed(() => menuItems.value.filter(menuItem => menuItem.id === menuId).pop())
    }

    function countTodos(itemRef: MaybeRef<VfMenuItem>) {
        //globalState?.todos?.find(i => i.route === item.route)?.count
        return computed<number>(() => {
            const item = unref(itemRef)
            if (!item) {
                return 0
            }

            const walkDown = (item: VfMenuItem): number => {
                const my = globalState.value?.todos?.find(i => i.route === item.route)?.count ?? 0

                let childrenCount = 0
                if (item.children) {
                    childrenCount = item.children.reduce((acc, child) => acc + walkDown(child), 0)
                }

                return my + childrenCount
            }
            return walkDown(item)
        })
    }

    /*─────────────────────────────────────┐
    │        my office / my admin          │
    └─────────────────────────────────────*/
    const myOfficeRoute = computed(() => {
        if (!hasRootMenu("my-office")) {
            return null
        }

        return getFirstChildRoutingData(getRootMenu("my-office"))
    })

    const myAdminRoute = computed(() => {
        if (!hasRootMenu("my-admin")) {
            return null
        }

        return getFirstChildRoutingData(getRootMenu("my-admin"))
    })

    const devToolsRoute = computed(() => {
        if (!hasRootMenu("dev-tools")) {
            return null
        }

        return getFirstChildRoutingData(getRootMenu("dev-tools"))
    })

    const myStaffRoute = computed(() => {
        if (!hasRootMenu("my-staff")) {
            return null
        }

        return getFirstChildRoutingData(getRootMenu("my-staff"))
    })

    return {
        activeMenuItem,
        getFirstChildRoutingData,
        menuItems,
        onMenuChange,
        routableMenuItems,
        hasRootMenu,
        getRootMenu,
        myOfficeRoute,
        myAdminRoute,
        devToolsRoute,
        myStaffRoute,
        countTodos,
    } as const
}

function elementsContainElement(element: VfMenuItem, searchedElement: VfMenuItem | null) {
    if (!searchedElement) {
        return false
    }

    if (element.route === searchedElement.route && element.params == searchedElement.params) {
        return true
    }

    if (element.children && element.children.length) {
        for (const child of element.children) {
            if (elementsContainElement(child, searchedElement)) {
                return true
            }
        }
    }

    return false
}
